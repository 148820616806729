<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import VueEasyLightbox from "vue-easy-lightbox";
import { apiRequest } from "@/helpers/api-call";

import { format } from "date-fns";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Détails trajets à venir",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, VueEasyLightbox },
  data() {
    return {
      title: "Détails trajets effectués",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "details",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25],
      filter: null,
      filterOn: [],
      date: "",
      dateDepart: "",
      dateArrive: "",
      tableData: [],
      passagerId: [],
      dataUser: [],
      details: "",
      sortBy: "orderid",
      sortDesc: false,
      conducteur: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "billingname", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      fields: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "passager", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      passager: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "billingname", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      username: "",
      dataUserTable: [],
      avatar: "",
      tokenUser: JSON.parse(localStorage.getItem("user")).tokenSalt,
      idtrajet: "",
      fisrtname: "",
      lastname: "",
      genre: "",
      boite_postale: "",
      profession: "",
      birthday: "",
      adress: "",
      city: "",
      country: "",
      number_phone: "",
      email: "",
      password: "",
      showPassword: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      index: 0,
      lieuArrivee: "",
      lieuDepart: "",
      allImage: {
        avatar: "",
        permis: "",
        vehicule: "",
        cni: "",
      },
      img: "",
      vue: false,
      visible: false,
      showModal: false,
      HistoriqueConducteur: false,
      HistoriquePassager: false,
      picture: ["PROFIL", "CNI", "PERMIS", "VOITURE"],
      imgs: "",
      telephoneUser: "",
    };
  },
  allImage: {
    avatar: "",
    permis: "",
    vehicule: "",
    cni: "",
  },
  async mounted() {
    this.idtrajet = this.$route.params.idtrajet;

    //"admin/trajets/activites/?id=" + this.idUser,
    const data = await apiRequest("GET", "trajet/?id=" + this.idtrajet, false);
    if (data && data.data) {
      this.tableData = data.data;
      (this.date = new Date(this.tableData.dateHeureDepart).toLocaleString(
        "fr-FR"
      )),
        (this.dateDepart = format(
          new Date(this.tableData.dateHeureDepart),
          "hh:mm:ss"
        ));
      this.dateArrive = new Date(this.tableData.dateHeureArrive).toLocaleString(
        "fr-FR"
      );
      this.lieuArrivee = this.tableData.lieuArrivee.nom;
      this.lieuDepart = this.tableData.lieuDepart.nom;
      this.telephoneUser = this.tableData.conducteur.username;
      if (data.data.conducteur.userAvatarCode !== null) {
        this.allImage.avatar =
          `https://rmobility.ovh/admin/image/?codeImage=` +
          data.data.conducteur.userAvatarCode +
          `&token=` +
          this.tokenUser;
      } else {
        this.allImage.avatar = require("@/assets/images/users/attente.png");
      }

      if (data.data.conducteur.vehicule.autoMotoPictureCode !== null) {
        this.allImage.permis =
          `https://rmobility.ovh/admin/image/?codeImage=` +
          data.data.conducteur.vehicule.autoMotoPictureCode +
          `&token=` +
          this.tokenUser;
      } else {
        this.allImage.permis = require("@/assets/images/users/attente.png");
      }
    }

    var lespassagers = data.data.passagers;

    this.passagerId = [];
    lespassagers.forEach((element) => {
      if (element.statut == "ACTIF") {
        this.passagerId.push(element.idClient);
      }
    });

    for (let i = 0; i < this.passagerId.length; i++) {
      const Users = await apiRequest(
        "POST",
        "admin/detail-user",
        { id: this.passagerId[i] },
        false
      );

      if (Users && Users.data) {
        this.dataUserTable.push(Users.data);
      }
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;

      this.$v.$touch();
    },

    ValidationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    Submit: function () {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      alert("data submit");
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },

    showImage(img) {
      this.imgs = img.srcElement.src;

      this.show();
    },

    handleHide() {
      this.visible = false;
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    show() {
      this.visible = true;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <vue-easy-lightbox
      :visible="visible"
      :imgs="imgs"
      @hide="handleHide"
    ></vue-easy-lightbox>
    <!--   <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center"></div>
            <div class="row py-5">
              <div class="col-md-4">
                <div class="row">
                  <a
                    class="image-popup-vertical-fit d-flex justify-content-center"
                    @click="showImage"
                  >
        
                    <img
                      style="cursor: pointer"
                      class="avatar-xl"
                      :src="`${this.allImage.avatar}`"
                      data-holder-rendered="true"
                    />
                  </a>
                  <vue-easy-lightbox
                    :visible="visible"
                    :imgs="imgs"
                    @hide="handleHide"
                  ></vue-easy-lightbox>
                  <div class="mt-4">
                    <i class="fas fa-user align-middle mr-2 ml-2"></i>
                    <span class="font-weight-bold"
                      >{{ this.tableData.conducteur.personne.firstname }}
                      {{ this.tableData.conducteur.personne.lastname }}</span
                    >
                    <div>
                      <i class="fas fa-comment mr-2 ml-2"></i
                      ><span>"{{ this.tableData.infosSup }}"</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <a
                    class="image-popup-vertical-fit d-flex justify-content-center"
                    @click="showImage"
                  >
                    <img
                      style="cursor: pointer"
                      class="avatar-xl mt-5"
                      :src="`${this.allImage.permis}`"
                      data-holder-rendered="true"
                    />
                  </a>
                  <div class="mt-5">
                    <div class="mt-3">
                      <i class="fas fa-fan mr-2 ml-2"></i>Climatisation:
                      <span>{{
                        this.tableData.conducteur.vehicule.aClimatisation ==
                        false
                          ? "Non"
                          : "Oui"
                      }}</span>
                    </div>
                    <div class="">
                      <i class="fas fa-fan ml-2 mr-1"></i> Immatriculation :
                      <span
                        >{{
                          this.tableData.conducteur.vehicule
                            .autoMotoImmatriculation
                        }}
                        min</span
                      >
                    </div>
                    <div class="">
                      <i class="fas fa-palette ml-2 mr-2"></i> Couleur :
                      <span>{{
                        this.tableData.conducteur.vehicule.autoMotoCouleur
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <span class="vertical-line"></span>
              <div class="" v-for="(item, index) in dataUserTable" :key="index">
                <div class="row">
                  <a class="image-popup-vertical-fit ml-5" @click="showImage">
                    <img
                      style="cursor: pointer; width: 50px"
                      class="avatar avatar-15 rounded-circle"
                      :src="`https://rmobility.ovh/admin/image/?codeImage=${item.userAvatarCode}&token=${tokenUser}`"
                      data-holder-rendered="true"
                    />
                  </a>

                  <div class="ml-3">
                    {{ item.personne.firstname + " " + item.personne.lastname }}

                    <span
                      class="fas fa-map-marker-alt text-primary ml-2 mr-1"
                    ></span
                    >{{ tableData.lieuDepart.nom }}

                    <span
                      class="fas fa-map-marker-alt text-danger ml-2 mr-1"
                    ></span
                    >{{ tableData.lieuArrivee.nom }}

                    <span class="badge badge-success"
                      >{{ tableData.prix }} XOF</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row backrow">
      <div class="right-section">
        <div class="card-identity">
          <a class="image-contain" @click="showImage">
            <!-- class=" avatar-xl d-flex justify-content-center" -->
            <img
              style="cursor: pointer"
              class="avatar-xl"
              :src="`${this.allImage.avatar}`"
              data-holder-rendered="true"
            />
          </a>

          <h3 class="unername">
            {{ this.tableData.conducteur.personne.firstname }}
            <br />
            {{ this.tableData.conducteur.personne.lastname }}
            <br />
            <span class="user-phone">{{ this.telephoneUser }}</span>
          </h3>
        </div>
      </div>
      <div class="left-section">
        <div class="commentaire">
          <p class="comment">“{{ this.tableData.infosSup }}”</p>
        </div>
        <div class="flexible-row row">
          <div class="card-identity-special dimension col-md-5 col-sm-6">
            <div class="row flexible-card">
              <a class="image-contain col-sm-6 col-md-6" @click="showImage">
                <!-- class=" avatar-xl d-flex justify-content-center" -->
                <img
                  style="cursor: pointer"
                  class=""
                  :src="`${this.allImage.permis}`"
                  data-holder-rendered="true"
                />
              </a>

              <div class="detail-info col-sm-6 col-md-5">
                <div class="infos">
                  <span> 🚀 Immatriculation: </span
                  >{{
                    this.tableData.conducteur.vehicule.autoMotoImmatriculation
                  }}
                </div>
                <div class="infos">
                  🚀 <span>Climatisation: </span
                  >{{
                    this.tableData.conducteur.vehicule.aClimatisation == false
                      ? "Non"
                      : "Oui"
                  }}
                </div>

                <div class="infos">
                  🚀 <span>Couleur: </span
                  >{{ this.tableData.conducteur.vehicule.autoMotoCouleur }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-1 middle_card"></div>
          <div class="trajet col-md-6 col-sm-6">
            <div class="left">
              <i class="fas fa-map-pin"></i>
              <div class="trait-route"></div>
              <i class="fas fa-map-marker-alt"></i>
            </div>
            <div class="right">
              <div class="depart">
                <small>Départ</small>
                <span>{{ this.lieuDepart }}</span>
              </div>
              <div class="separate"></div>
              <div class="arrive">
                <small>Arrivé</small>
                <span>{{ this.lieuArrivee }}</span>
              </div>
              <div class="flexible-date">
                <span class="special-span">
                  <i class="fa fa-calendar-o"></i> {{ this.date }}
                </span>
                <span class="special-span">
                  <i class="fa fa-calendar-o"></i> {{ this.dateArrive }}
                </span>
              </div>
              <div class="" v-for="(item, index) in dataUserTable" :key="index">
                <div class="row">
                  <a class="image-popup-vertical-fit ml-5" @click="showImage">
                    <img
                      style="cursor: pointer; width: 50px"
                      class="avatar avatar-15 rounded-circle"
                      :src="`https://rmobility.ovh/admin/image/?codeImage=${item.userAvatarCode}&token=${tokenUser}`"
                      data-holder-rendered="true"
                    />
                  </a>

                  <div class="ml-3">
                    {{ item.personne.firstname + " " + item.personne.lastname }}

                    <!--  <span
                      class="fas fa-map-marker-alt text-primary ml-2 mr-1"
                    ></span
                    >{{ tableData.lieuDepart.nom }}

                    <span
                      class="fas fa-map-marker-alt text-danger ml-2 mr-1"
                    ></span
                    >{{ tableData.lieuArrivee.nom }}

                    <span class="badge badge-success"
                      >{{ tableData.prix }} XOF</span
                    > -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
label {
  font-size: 20px;
}
.divider {
  width: 100%;
  border: solid 2px blue;
}
.blueG {
  border: solid 1px #8608b8ad;
}
.YelG {
  border: solid 1px #ff6545;
}
.text-blueG {
  color: #8608b8ad;
}
.vertical-line {
  border-left: 2px solid #000;
  height: 430px;
  margin: 0 20px;
}

/** Profile info style */
.backrow {

  display: flex;
flex-wrap: wrap;
justify-content: space-between;
gap: 10px;
}
.card-identity {
  flex: 1; /* This property makes the items flexible and expand equally */
  min-width: 370px; 

  border-radius: 30px;
  background: #fff;
  display: flex;


  align-items: center;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  margin-top: 2rem;
  margin-right: 2rem;
  padding: 2rem 1rem;
justify-content: space-between;
  gap:5px;
}

.card-identity-special{
  flex: 1; /* This property makes the items flexible and expand equally */
  min-width: 440px; 

  border-radius: 30px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  margin-top: 2rem;
 margin-right: 1rem;
 padding: 0 1rem;


}
.card-identity-special img{
  width: 100%;
  height: 150px;
  border-radius: 30px;
  object-fit: cover;
}
.card-identity img {

  height: 150px;
  border-radius: 30px;
  object-fit: cover;
}
.card-identity-special .unername{
  font-family: "Raleway", serif;
  font-size: 20px;
  font-weight: 600;
  color: #0d0d0d;
  line-height: 35px;
}


.card-identity .unername {
  font-family: "Raleway", serif;
  font-size: 30px;
  font-weight: 600;
  color: #0d0d0d;
  line-height: 45px;
}

.commentaire {
  width: 100%;
  height: 95px;
  border-radius: 20px;
  background: #fff;

  box-shadow: 1px 1px 0.5px rgba(0, 0, 0, 0.3);
  margin-top: 2rem;


  padding: 2rem 1rem;
}

.commentaire .comment {
  font-family: "Raleway", serif;
  font-size: 20px;
  font-weight: 400;
  color: #0d0d0d;
  text-align: center;
}

.detail-info {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;



 
}

.infos {
  margin-bottom: 0.6rem;
}

.infos span {
  font-weight: bold;
  color: #000;
  white-space: nowrap;
}

.dimension {
  height: 170px;
}

.trajet {

  min-width: 450px; 
  height: 260px;
  border-radius: 20px;
  background: #fff;
width: 100%;
  box-shadow: 1px 1px 0.5px rgba(0, 0, 0, 0.3);
  margin-top: 2rem;

  padding: 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trajet .left {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.trajet .right {
  display: flex;
  flex-direction: column;

  flex-basis: 90%;
}

.left .trait-route {
  text-align: center;
  padding: 0;
  height: 80px;
  width: 0;
  border: 1px dashed #d8d8d8;
}
.fa-map-pin {
  font-size: 15px;
  color: #dc143c;
}

.fa-map-marker-alt {
  font-size: 15px;
  color: #4ce5b1;
}

.depart small {
  color: #c8c7cc;
  font-size: 13px;
  font-weight: 400;
}

.depart {
  margin-top: 15px;
}
.depart,
.arrive {
  display: flex;
  flex-direction: column;
}
.depart .span {
  color: #242e42;
  font-size: 17px;
  font-weight: 400;
}

.arrive small {
  color: #c8c7cc;
  font-size: 13px;
  font-weight: 400;
}

.arrive span {
  color: #242e42;
  font-size: 13px;
  font-weight: 400;
}
.special-span {
  color: #242e42;
  font-size: 13px;
  font-weight: 400;
  box-shadow: 1px 1px 2px rgba(55, 54, 54, 0.3);
  border-radius: 10px;
  margin-top: 5px;
  width: 100px;
}
.separate {
  height: 60px;
}

.arrive {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 0.5rem;
  margin-bottom: 10px;
}
.flexible-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
 padding-right: 1.2rem;
}

.flexible-date {
  display: flex;
  justify-content: space-between;
}
/**4CE5B1 */

.user-phone {
  display: block;
  font-weight: 400;
  color: #242e42;
  font-size: 20pt;
}

.left-section{
flex: 0.7;
}

.right-section{
  flex:0.2;
}

.flexible-card{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}


@media screen and (max-width: 1970px) {
  body {
    background-color:   rgba(247,247,248,1);
   
  }

  .card-identity-special{
    margin-right: 0;
  }

  

 
  .right-section{
   width: 100%;
  }
  .commentaire{
   margin-left:  auto;
   margin-right:  auto;
  }


.middle_card{
  display: none;
}
}

@media screen and (max-width: 1051px) {
  body {
    background-color:   rgba(247,247,248,1);
   
  }

  .card-identity-special{
    margin-right: 0;
    width:410px
  }
  .card-identity{
    margin-right: 0;
   width:410px
  }

  .flexible-row{
    padding-right: 0;
  }
  .left-section{
    flex: 1;
    min-width: 410px;
  }
  
  .commentaire{
   margin-left:  0;
 
   width:410px;
  }


  .middle_card{
  display: none;
}

}


@media screen and (max-width: 750px) {
.backrow{
    min-width: 740px;
  
    padding: 2rem;
  }

 
.card-identity-special img{
width: 125px;
height: 125px;
}

.flexible-card{
  flex-wrap: nowrap;
 
}

.card-identity-special{
width: 410px!important;
flex: none;
}

.trajet{
width: 410px!important;
flex: none;

}

.detail-info{
  width: 100%;
}

.middle_card{
  display: none;
}
}


body{
  min-width: 600px;
}
  
</style>
